import { ShipdaCurrentLanguageType } from "@sellernote/_shared/src/i18n/i18nForShipda";

type DefaultSEOKey =
  | "title"
  | "description"
  | "siteName"
  | "author"
  | "publisher"
  | "subject";

/**
 * SEO 값을 커스터마이징하지 않을때 사용하는 기본값
 *
 * SEO가 필요한 페이지는 SEO 컴포넌트를 사용할때 title, desc를 항상 커스텀하며,
 * 마이페이지처럼 SEO 커스터마이징을 적용하지 않는 페이지들에서 쓰이게 됨
 */
const DEFAULT_SEO: Record<
  ShipdaCurrentLanguageType,
  {
    [K in DefaultSEOKey]: string;
  }
> = {
  ko: {
    title: "쉽다 - 디지털 수출입 물류 포워딩 서비스",
    description:
      "디지털 포워딩 서비스 - 쉽다(ShipDa). 수출입운송부터 국내4PL 풀필먼트까지, 디지털 통합운송 솔루션",
    siteName: "ShipDa",
    author: "쉽다 ShipDa",
    publisher: "쉽다 ShipDa",
    subject: "포워딩",
  },
  en: {
    title: "Digital freight forwarding service - ShipDa",
    description:
      "Digital Freight Forwarding Service, covering everything from global export/import transportation to Korea, to domestic 4PL fulfillment.",
    siteName: "ShipDa",
    author: "ShipDa",
    publisher: "ShipDa",
    subject: "Forwarding",
  },
};

export { DEFAULT_SEO };
