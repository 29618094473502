import { useMemo } from "react";
import { useRouter } from "next/router";
import { TFunction, useTranslation } from "next-i18next";

import { SERVICE_INTRODUCTION_INQUIRY_URL } from "@sellernote/_shared/src/constants";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import BoxesIcon from "@sellernote/_sds-v2/src/components/svgIcons/BoxesIcon";
import CalendarIcon from "@sellernote/_sds-v2/src/components/svgIcons/CalendarIcon";
import MailIcon from "@sellernote/_sds-v2/src/components/svgIcons/MailIcon";
import Plane3Icon from "@sellernote/_sds-v2/src/components/svgIcons/Plane3Icon";
import QuoteIcon from "@sellernote/_sds-v2/src/components/svgIcons/QuoteIcon";
import TruckMoving2Icon from "@sellernote/_sds-v2/src/components/svgIcons/TruckMoving2Icon";
import WritingIcon from "@sellernote/_sds-v2/src/components/svgIcons/WritingIcon";
import useCheckIsFulfillmentPage from "@sellernote/shipda-kr/src/hooks/common/useCheckIsFulfillmentPage";

import QuickActionsForDesktop from "./QuickActionsForDesktop";
import QuickActionsForMobile from "./QuickActionsForMobile";

export interface QuickActionItem {
  label: string;
  path?: string;
  Icon: (props: React.SVGAttributes<SVGElement>) => JSX.Element;
  isExternalLink?: boolean;
  handleClick?: () => void;
  gtmEvent?: string;
}

const getFulfillmentQuickAction = (
  t: TFunction
): Record<"domestic" | "overseas" | "event", QuickActionItem> => ({
  domestic: {
    label: t("components:landing.국내_출고"),
    path: "/fulfillment/domestic",
    Icon: TruckMoving2Icon,
  },
  overseas: {
    label: t("components:landing.해외_출고"),
    path: "/fulfillment/overseas",
    Icon: Plane3Icon,
  },
  event: {
    label: t("components:landing.이벤트_출고"),
    path: "/fulfillment/event",
    Icon: BoxesIcon,
  },
});

const getFulfillmentQuickActionList = (asPath: string, t: TFunction) => {
  const pageName = asPath.split("/").pop();

  return Object.entries(getFulfillmentQuickAction(t)).reduce<QuickActionItem[]>(
    (prev, [key, value]) => {
      if (key !== pageName) {
        return [...prev, value];
      }

      return prev;
    },
    []
  );
};

export default function LandingQuickActions({
  openServiceGuideModal,
}: {
  openServiceGuideModal?: () => void;
}) {
  const { isFulfillmentPage } = useCheckIsFulfillmentPage();

  const { t } = useTranslation(["main-landing", "components"]);

  const { isMobile } = useCheckIsMobile();

  const asPath = useRouter().asPath;

  const fulfillmentQuickActionList = useMemo(() => {
    if (!isFulfillmentPage) {
      return [];
    }

    return getFulfillmentQuickActionList(asPath, t);
  }, [asPath, isFulfillmentPage, t]);

  const quickActionList: QuickActionItem[] = useMemo(() => {
    return [
      ...(isMobile
        ? [
            ...(openServiceGuideModal
              ? [
                  {
                    label: isFulfillmentPage
                      ? t("components:landing.풀필먼트_소개서_요청")
                      : t("components:landing.쉽다_소개서_요청"),
                    Icon: MailIcon,
                    isExternalLink: true,
                    handleClick: openServiceGuideModal,
                  },
                ]
              : []),
            {
              label: t("main-landing:바로가기버튼_도입문의"),
              path: SERVICE_INTRODUCTION_INQUIRY_URL,
              Icon: WritingIcon,
              isExternalLink: true,
            },
          ]
        : []),
      ...(isFulfillmentPage
        ? fulfillmentQuickActionList
        : [
            {
              label: t("components:landing.견적의뢰"),
              path: "/forwarding/quote",
              Icon: QuoteIcon,
              gtmEvent: "landing_floating_btn_quote",
            },
            {
              label: t("components:landing.스케줄_조회"),
              path: "/forwarding/schedule",
              Icon: CalendarIcon,
              gtmEvent: "landing_floating_btn_schedule",
            },
          ]),
    ];
  }, [
    fulfillmentQuickActionList,
    isFulfillmentPage,
    isMobile,
    openServiceGuideModal,
    t,
  ]);

  return (
    <>
      {isMobile ? (
        <QuickActionsForMobile list={quickActionList} />
      ) : (
        <QuickActionsForDesktop list={quickActionList} />
      )}
    </>
  );
}
